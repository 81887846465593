* {
  font-family: Roboto-Regular, sans-serif;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../../assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../../assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../../assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto-BoldItalic";
  src: url("../../assets/fonts/Roboto-BoldItalic.ttf");
}

.logout h6 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 8rem;
}
