* {
  font-family: Roboto-Regular, sans-serif;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../../assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Roboto-Medium";
    src: url("../../assets/fonts/Roboto-Medium.ttf");
  }

@font-face {
    font-family: "Roboto-Bold";
    src: url("../../assets/fonts/Roboto-Bold.ttf");
  }

@font-face {
    font-family: "Roboto-BoldItalic";
    src: url("../../assets/fonts/Roboto-BoldItalic.ttf");
  }
@font-face {
    font-family: "Roboto-Italic";
    src: url("../../assets/fonts/Roboto-Italic.ttf");
  }

@font-face {
    font-family: "OpenSans-Regular";
    src: url("../../assets/fonts/OpenSans-Regular.ttf");
  }

  .customPanel .ant-collapse-content-box {
    padding: 0;
   }
