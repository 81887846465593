.subhead {
  width: 100%;
  max-height: 10vh;
  display: flex;
  align-items: center;
  /* padding: 0; */
}

.banner {
  width: 100%;
  max-height: 30vh;
  margin-top: 1rem;
  background: #f6f7f8;
  display: flex;
  align-items: center;
}

.associatesFeedback{
  width: 100%;
  max-height: 100vh;
  margin-bottom: 1rem;
  /* border: 1px solid red;   */
}

/* .feedbackThemselvesTable{
  border: 1px solid blue;
} */

.feedbackThemselvesTable ::-webkit-scrollbar{
  width:0.3rem;
  height: 1rem;
  /* display:none; */
}

  
.feedbackThemselvesTable ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(242, 240, 240, 0.3);
}
 
.feedbackThemselvesTable ::-webkit-scrollbar-thumb {
  height: 56px;
  /* background-color: rgb(236, 230, 230); */
  background-color: #979797;
  border-radius: 10px;
  
}


