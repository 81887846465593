.header {
    background-color: white;
    width: 100%;
    max-height: 20vh;
    padding-left: 1rem;
    display: flex;
    align-items: center;
}
.header img{
    width: 8rem;
    height: 4rem;
    display: flex;
    justify-content: flex-start;
    margin: 0.5rem auto auto 0.5rem;
}



.log-btn{
    background-color: black;
    color: white;
    font-size: 15px;
    padding: 8px 30px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
}