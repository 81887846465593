/* @import 'antd/dist/antd.css'; */



.userTable ::-webkit-scrollbar {
  width:0.3rem;
  height: 1rem;
}

.userTable ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(242, 240, 240, 0.3);
}

.userTable ::-webkit-scrollbar-thumb {
  height: 56px;
  /* background-color: rgb(236, 230, 230); */
  background-color: #979797;
  border-radius: 10px;
}
