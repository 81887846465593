body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  /* width: 1rem; */
  display: none;
}

body::-webkit-scrollbar-track {
 box-shadow: inset 0 0 5px rgb(182, 181, 181);
background: #ffffff;
}

body::-webkit-scrollbar-thumb {
  color: rgba(6, 6, 6, 0);
  border-radius: 10px;
  width: 0.5rem;
  height: 5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container {
  padding-left: 50px;

  /* padding-right: 50px; */
}
.margin {
  margin: 1rem 50px 50px 40px;
  border: 2px solid #45c9f1;
}
.containered {
  text-align: center;
  /* padding-right: 50px; */
}
hr.solid {
  border-top: 3px solid rgb(95, 37, 255);
}
.head {
  padding-left: 50px;
  /* padding-right: 50px; */
}
.Button {
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  /* display: flex; */
  /* justify-content: flex-end; */
}
.containing {
  padding-left: 50px;
  /* padding-right: 50px; */
  height: 70vh;
  overflow-y: scroll;
}
.Button1 {
  /* padding-bottom: 10px; */
  padding-left: 50px;
  padding-right: 50px;
  /* display: flex; */
  /* justify-content: flex-end; */
}
.contained {
  padding-left: 50px;
  padding-right: 30px;
}
.div {
  width: 300px;
  border: 5px solid skyblue;
  padding: 20px;
  margin: 20px;
}

.p {
  padding-left: 50px;
  padding-right: 30px;
}
.V {
  border-left: 6px solid rgb(0, 92, 128);
  border-top: 6px solid rgb(0, 92, 128);
}
.card {
  margin: 50px 50px 50px 40px;
  border: 2px solid #45c9f1;
  box-shadow: 10px 10px 10px 10px rgba(6, 32, 121, 0.2);
  transition: 0.3s;
  width: 250px;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.card img {
  border-radius: 50%;
}
hr.sol {
  border-top: 3px solid rgb(10, 148, 202);
}
hr.solid {
  border-top: 3px solid rgb(10, 148, 202);
}

.full-width {
  width: 100% !important;
}
.transform {
  /* position: relative; */
  /* top: 100px; */
  /* left: 100px; */
  /* transform-origin: 100px 100px; */
  /* transform: rotate(270deg);  */
  /* left: 50%; */
  /* top: 50%; */
  /* transform: translate(50%,50%); */
  /* position: absolute; */

  transform: rotate(270deg);
}
.box {
  background-color: lightgrey;
  width: 300px;
  border: 15px solid rgb(14, 121, 170);
  padding: 50px;
  margin: 20px;
}
